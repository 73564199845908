"use client";

import { useGetHome } from "./_api";
import {
  Banner,
  Clients,
  Hero,
  KeyWords,
  PublicListening,
  ScrollyTelling,
  Stats,
  Testimonial,
  VideoJumbo,
} from "./_components/sections";

import WGS from "./_components/wgs";

import Image from "next/image";
import wgsDesktopBackground from "public/static/images/home/wgs-desktop-bg.png";
import wgsMobileBackground from "public/static/images/home/wgs-mobile-bg.png";

import React, { useState, useEffect } from "react";

export default function Page() {
  const { data } = useGetHome();

  const { keywords } = data;

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // Si el ancho de la ventana es menor o igual a 768px, es móvil
      setIsMobile(window.innerWidth <= 768);
    };

    // Llamamos a handleResize para establecer el valor inicial
    handleResize();

    // Escuchar los cambios de tamaño de la ventana
    window.addEventListener("resize", handleResize);

    // Limpiar el evento al desmontar el componente
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="relative overflow-hidden">
        <div className="relative z-10">
          <Hero />
        </div>
      </div>
      <div className="relative mx-4">
        <Image
          src={isMobile ? wgsMobileBackground : wgsDesktopBackground}
          alt="WGS"
          className="absolute inset-0 h-full z-0 bg-center opacity-40"
        />
        <WGS />
      </div>

      <VideoJumbo />

      <ScrollyTelling />

      <Stats />

      <Clients />

      <Testimonial />

      <KeyWords {...keywords} />

      <PublicListening />

      <Banner />
    </>
  );
}
